.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}
.row h2 {
  margin-bottom: 20px;
  font-size: 30px;
}
.firstIntoRow {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}
.firstIntoRow p {
  max-width: 300px;
  margin: 0 15px;
  font-size: 24px;
}
@media screen and (max-width: 360px) {
  .firstIntoRow p {
    font-size: 18px;
  }
}

.firstIntoRow span {
  font-size: 36px;
}
.firstIntoRow img {
  /* width: 300px; */
  max-width: 45vw;
  object-fit: contain;
  border-radius: 50%;
  background-color: #fff;
  padding: 4px;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.6);
  margin-right: 5px;
}

.secondMlRow {
  background-color: #fffafa;
}

.cardsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* max-width: 1200px; */
  flex-wrap: wrap;
}
.thirdEntrepreneurRow {
  background-color: #f0e9e9;
}

.fourthContactsRow {
  background-color: #d8cfcf;
}

.cardWrapper {
  margin: 1vh 2.5vw;
  max-width: 90vw;
}
.revealButton {
  background-color: rgb(39, 86, 216, 0.6);
  color: #fff;
  border: none;
  text-decoration: none;
  padding: 2px 5px;
  border-radius: 10px;
  margin-top: 5px;
}
.contacts {
  margin: 0 20px;
}
.contacts div {
  display: flex;
  max-width: 95vw;
}
.contacts div p {
  margin-right: 10px;
}
.contacts div a {
  word-break: break-all;
}
