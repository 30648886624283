body {
  margin: 0;
  font-family: "Noto Sans JP", "Roboto", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

p,
h2 {
  color: #4d4d4d;
}
h3 {
  margin: 10px;
}
