.cardContainer {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
  border-radius: 10px;
  height: 100%;
}
.cardContainer img {
  /* object-fit: fill; */
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  /* min-width: 50%; */
  /* height: fit-content; */
  max-width: -webkit-fill-available;
}
.simpleCard {
  max-width: 300px;
  /* min-width: 320px; */
}
.codeCard {
  max-width: 500px;
  /* min-width: 500px; */
}
.codeCard p {
  margin: 0 20px;
}
.simpleCard img {
  width: calc(100%);
  margin-top: 15px;
}
.imgRow {
  display: flex;
  padding: 0 16px;
}
.cardContainer p {
  font-size: 18px;
}
@media screen and (max-width: 360px) {
  .cardContainer p {
    font-size: 16px;
  }
}

.linksRow {
  display: flex;

  justify-content: space-around;
  margin: 10px 0;
}
.link {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.link img {
  height: auto;
  border: none;
  width: 4px;
  margin-right: 4px;
  box-shadow: none;
  object-fit: contain;
  min-width: 50%;
}
.link + .link img {
  width: 6px;
}
.linkContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  text-decoration: none;
  padding: 30px;
  justify-content: space-between;
}
.safariSpecialImg{
  min-width: 50%;
}